<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>物流管理</el-breadcrumb-item>
        <el-breadcrumb-item>运费模板</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow top-flex-end">
            <div class="flexRow"  v-if="false">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">模板名称：</div>
                <el-input v-model="search.promotionName" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="freightTempAdd" @click="goAdd">新增运费模板</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="template.title" label="模板名称"></el-table-column>
            <el-table-column align="center" prop="template.simAddress" label="发货地区"></el-table-column>
            <el-table-column align="center" prop="template.updateDate" label="运费设置">
              <template slot-scope="scope">{{ scope.row.template.type | getType }}</template>
            </el-table-column>
            <el-table-column align="center" prop="template.updateDate" label="计价方式">
              <template slot-scope="scope">{{ scope.row.template.valuationWay | getValuationWay }}</template>
            </el-table-column>
            <el-table-column align="center" prop="template.updateDate" label="最后修改时间"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="freightTempUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="freightTempDel" @click="del(scope.row.template.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let _this = this;
export default {
  data() {
    _this = this;
    return {
      labelPosition: 'top',
      search: {
        title: '',
      },
      tableList: [],
      upList: [],
      type: [{value: 0, label: '阶梯计价'}, {value: 1, label: '固定运费'}],
      valuationWay: [{value: 0, label: '按件数'}, {value: 1, label: '按重量'}],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      freightTempAdd: false,
      freightTempDel: false,
      freightTempUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  filters: {
    getType(value) {
      const data = _this.type.filter(item => item.value == value);
      // 确保有值
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getValuationWay(value) {
      const data = _this.valuationWay.filter(item => item.value == value);
      // 确保有值
      if (data && data.length > 0) {
        return data[0].label;
      }
    }
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getTemp(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "freightTemp:add") {
          _this.freightTempAdd = true;
        }
        if (v == "freightTemp:del") {
          _this.freightTempDel = true;
        }
        if (v == "freightTemp:upd") {
          _this.freightTempUpd = true;
        }
      })
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          _this.upData.promotionDiscount = _this.upData.promotionDiscount*10;
          if (!_this.upData.id) {
            _this.$api.addSalesPromotion(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("添加成功");
                _this.handleClose();
                _this.getData();
              }
            });
          } else {
            _this.$api.editSalesPromotion(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("修改成功");
                _this.getData();
                _this.handleClose();
              }
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        promotionCondition: null,
        promotionDiscount: null,
        promotionName: "",
        promotionType: null,
        shelvesStatus: 1,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        promotionName: '',
        shelvesStatus: null
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.$router.push('/addFreightTemp?type=add')
    },
    details(e) {
      let _this = this;
      _this.$router.push('/addFreightTemp?type=details&id='+e.template.id)
    },
    edit(e) {
      let _this = this;
      _this.$router.push('/addFreightTemp?type=edit&id='+e.template.id)
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delTemp({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}
.top-flex-end {
  justify-content: flex-end;
}
</style>
